import * as React from 'react';
import Card from '@mui/material/Card';
import { useGlobalStore } from '../utilities/GlobalState';
import { Button, Modal, TextField, Typography } from '@mui/material';
import { Checks } from '../interfaces/Interfaces';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { Post } from '../utilities/Requests';

interface Props {
    open: boolean;
    handleClose: () => void;
    workcenter: string;
    startJob: () => void;
}

export const ChecklistModal = (props: Props) => {
    const globalState = useGlobalStore((state) => state);
    const [checks, setChecks] = React.useState<Checks>({
        id: 0,
        machine_checklist_result: uuidv4(),
        checked_by: globalState?.employee?.employee || '',
        checked_date: DateTime.now().setZone('America/New_York').toISO() || '',
        machine: '',
        coolant_level: '',
        coolant_concentrate_level: '',
        way_oil: '',
        run_skimmer: '',
        horizontal_hydraulic_oil: '',
        horizontal_spindle_oil: '',
        machine_type: '',
    });

    if (!checks?.machine?.length && props?.workcenter?.length) {
        setChecks((prev: Checks) => {
            return {
                ...prev,
                machine: props.workcenter,
            };
        });
    }

    const changeText = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
    ) => {
        const value: string = e.target.value;

        setChecks((prev: Checks) => {
            return {
                ...prev,
                [key]: value,
            };
        });
    };

    const submitChecklistAndStart = () => {
        Post('/MachineChecklistResult', checks).then(() => {
            props.startJob();
            props.handleClose();
        });
    };

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card style={{ maxWidth: 500, margin: 'auto', padding: 30 }}>
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    Machine Checklist
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    (All Checks Are REQUIRED)
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="coolant_level"
                    label="Coolant Level"
                    id="coolant_level"
                    variant="outlined"
                    onChange={(e) => changeText(e, 'coolant_level')}
                    value={checks?.coolant_level}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="coolant_concentrate_level"
                    label="Coolant Concentrate Level"
                    id="coolant_concentrate_level"
                    variant="outlined"
                    onChange={(e) => changeText(e, 'coolant_concentrate_level')}
                    value={checks?.coolant_concentrate_level}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="way_oil"
                    label="Way Oil"
                    id="way_oil"
                    variant="outlined"
                    onChange={(e) => changeText(e, 'way_oil')}
                    value={checks?.way_oil}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="run_skimmer"
                    label="Run Skimmer"
                    id="run_skimmer"
                    variant="outlined"
                    onChange={(e) => changeText(e, 'run_skimmer')}
                    value={checks?.run_skimmer}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="horizontal_hydraulic_oil"
                    label="Horizontal Hydraulic Oil"
                    id="horizontal_hydraulic_oil"
                    variant="outlined"
                    onChange={(e) => changeText(e, 'horizontal_hydraulic_oil')}
                    value={checks?.horizontal_hydraulic_oil}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="horizontal_spindle_oil"
                    label="Horizontal Spindle Oil"
                    id="horizontal_spindle_oil"
                    variant="outlined"
                    onChange={(e) => changeText(e, 'horizontal_spindle_oil')}
                    value={checks?.horizontal_spindle_oil}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="machine_type"
                    label="Machine Type"
                    id="machine_type"
                    variant="outlined"
                    onChange={(e) => changeText(e, 'machine_type')}
                    value={checks?.machine_type}
                />
                <Button
                    color="primary"
                    variant="contained"
                    style={{ width: '100%', height: '50px', marginTop: '16px' }}
                    onClick={submitChecklistAndStart}
                >
                    Start
                </Button>
            </Card>
        </Modal>
    );
};
