import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './styles/index.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container!); // TODO: remove "!" non-null assertion

root.render(
    <React.StrictMode>
        <BrowserRouter>
            {/* <Auth0ProviderWithNavigate> */}
            <App />
            {/* </Auth0ProviderWithNavigate> */}
        </BrowserRouter>
    </React.StrictMode>,
);
