import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ThemeProvider,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useGlobalStore } from '../utilities/GlobalState';
import { defaultTheme } from '../utilities/Utilites';

export const HeaderNavBar = () => {
    const globalState = useGlobalStore((state) => state);
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        globalState.setAuthenticated(false);
        globalState.clearState();
        useGlobalStore.persist.clearStorage();
        handleClose();
    };

    const randomNumber = Math.floor(Math.random() * 25);

    const salutationList = [
        'Greetings',
        'Salutations',
        'Hola',
        'Bonjour',
        'Guten Tag',
        'Welcome',
        'Howdy',
        'Yo',
        'Hi',
        'Heya',
        'Kaixo',
        'Bok',
        'Hej',
        'Tere',
        'Hallo',
        'Dia Duit',
        'Kon’nichiwa',
        'Annyeonghaseyo',
        'Salve',
        'Ola',
        'Alo',
        'Zdravo',
        'Swasdi',
        'Zdrastuyke',
        'Chào bạn',
    ];

    const firstName = globalState?.username?.split('.')[0] || '';
    const greeting = () => {
        return (
            <>
                {salutationList[randomNumber] +
                    ' ' +
                    `${
                        firstName.charAt(0).toUpperCase() + firstName.slice(1)
                    }!`}
            </>
        );
    };

    const drawerWidth = 240;

    const Main = styled('main', {
        shouldForwardProp: (prop) => prop !== 'open',
    })<{
        open?: boolean;
    }>(() => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }));

    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<AppBarProps>(() => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            // width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const DrawerHeader = styled('div')(() => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const sidebarNavItems = (
        <List>
            <ListItem key={'Work Management'} disablePadding>
                <ListItemButton href="/">
                    <ListItemIcon>
                        <PunchClockIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary={'Work Management'} />
                </ListItemButton>
            </ListItem>
            <ListItem key={'My Job Entries'} disablePadding>
                <ListItemButton href="/myjobs">
                    <ListItemIcon>
                        <WorkHistoryIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary={'My Job Entries'} />
                </ListItemButton>
            </ListItem>
        </List>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ThemeProvider theme={defaultTheme}>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        >
                            {globalState?.username ? greeting() : <></>}
                        </Typography>
                        {globalState?.authenticated && (
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorReference="anchorPosition"
                                    anchorPosition={{
                                        top: 10,
                                        left: visualViewport?.width || 500 - 10,
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {/* <MenuItem onClick={handleClose}>
                                        Profile
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        My account
                                    </MenuItem> */}
                                    <MenuItem onClick={handleLogout}>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? (
                                <ChevronLeftIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {sidebarNavItems}
                </Drawer>
                <Main open={open}></Main>
            </ThemeProvider>
        </Box>
    );
};
