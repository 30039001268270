import axios, { AxiosInstance } from 'axios';

// Blanket changes to all requests can be handled here. For example, all headers could be set or changed here.
// TODO remove ANY
// eslint-disable-next-line
interface CustomError extends Error {
    isSkipXHR: boolean;
    requestConfig: any;
}

// If the same url is hit repeatedly within 1000ms response will be cached and served to all requests. This will prevent multiple api calls to the same endpoint caused by re-renders.
const pendings: any = {};
const caches: any = {};
const cacheUtils: any = {
    getUniqueUrl: function (config: any) {
        return config.url + '&' + config.method;
    },
    isCached: function (config: any) {
        const uniqueUrl = this.getUniqueUrl(config);
        return caches[uniqueUrl] !== undefined;
    },
    isPending: function (config: any) {
        const uniqueUrl = this.getUniqueUrl(config);
        if (!pendings[uniqueUrl]) {
            pendings[uniqueUrl] = [config];
            return false;
        } else {
            pendings[uniqueUrl].push(config);
            return true;
        }
    },
    setCachedResponse: function (config: any, response: any) {
        const uniqueUrl = this.getUniqueUrl(config);
        caches[uniqueUrl] = response;
        if (pendings[uniqueUrl]) {
            pendings[uniqueUrl].forEach((configItem: any) => {
                configItem.isFinished = true;
            });
        }
    },
    getError: function (config: any) {
        const skipXHRError: CustomError = new Error('skip') as CustomError;
        skipXHRError.isSkipXHR = true;
        skipXHRError.requestConfig = config;
        return skipXHRError;
    },
    getCachedResponse: function (config: any) {
        const uniqueUrl = this.getUniqueUrl(config);
        return caches[uniqueUrl];
    },
};

const instance = (additionalHeaders?: any): AxiosInstance => {
    const newAxios = axios.create({
        baseURL: 'https://synthesis.aptmfg.com:7114',
        // baseURL: 'https://localhost:7114',
        headers: {
            ...additionalHeaders,
            'Content-Type': 'application/json',
            APIKey: 'd2a27b020b264952b043a1ea385c2130',
        },
        canCache: additionalHeaders?.canCache || false,
    });

    newAxios.interceptors.request.use(function (config: any) {
        // to avoid careless bug, only the request that explicitly declares *canCache* parameter can use cache
        if (config.canCache) {
            if (cacheUtils.isCached(config)) {
                const error = cacheUtils.getError(config);
                throw error;
            }
            if (cacheUtils.isPending(config)) {
                return new Promise((resolve, reject) => {
                    const interval = setInterval(() => {
                        if (config.isFinished) {
                            clearInterval(interval);
                            const error = cacheUtils.getError(config);
                            reject(error);
                        }
                    }, 200);
                });
            } else {
                // the head of cacheable requests queue, get the response by http request
                return config;
            }
        } else {
            return config;
        }
    });
    return newAxios;
};

// TODO remove ANY
// eslint-disable-next-line
const Get = (url: string, additionalHeaders?: any) =>
    instance(additionalHeaders).get(url);
// TODO remove ANY
// eslint-disable-next-line
const Post = (url: string, body: any, additionalHeaders?: any) =>
    instance(additionalHeaders).post(url, body);
// TODO remove ANY
// eslint-disable-next-line
const Put = (url: string, body: any, additionalHeaders?: any) =>
    instance(additionalHeaders).put(url, body);

export { Get, Post, Put };
