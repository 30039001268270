import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    convertMinutesToHours,
    formatDateNoTimeMonth,
    formatTimeNoDateIncludeTZ,
} from '../utilities/Utilites';
import { DateTime } from 'luxon';
import { Card } from '@mui/material';
import { useGlobalStore } from '../utilities/GlobalState';

export const LastWeek = () => {
    const [regTotal, setRegTotal] = React.useState(0);
    const [otTotal, setOtTotal] = React.useState(0);
    const [dtTotal, setDtTotal] = React.useState(0);
    const globalState = useGlobalStore((state) => state);

    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableCell2 = styled(TableCell)(() => ({
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            fontWeight: 1000,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    React.useEffect(() => {
        if (globalState?.lastWeekAttendances?.length) {
            let reg = 0;
            let ot = 0;
            let dt = 0;
            globalState?.lastWeekAttendances?.forEach((att) => {
                reg = reg + att.regMinutes;
                ot = ot + att.oTMinutes;
                dt = dt + att.dTMinutes;
            });
            setRegTotal(reg);
            setOtTotal(ot);
            setDtTotal(dt);
        }
    }, [globalState?.lastWeekAttendances]);

    return (
        <>
            {globalState?.lastWeekAttendances?.length ? (
                <Card sx={{ padding: '10px', margin: '10px' }}>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 762 }}
                            aria-label="customized table"
                        >
                            <TableHead sx={{ backgroundColor: 'lightgrey' }}>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        colSpan={6}
                                        sx={{
                                            fontSize: '20px',
                                        }}
                                    >
                                        Last Week
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="center">
                                        Date
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Clocked In
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Clocked Out
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Regular Hours
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Overtime Hours
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Doubletime Hours
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {globalState?.lastWeekAttendances?.map(
                                    (row, i) => (
                                        <StyledTableRow
                                            key={'StyledTableRow' + i}
                                        >
                                            <StyledTableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                            >
                                                {formatDateNoTimeMonth(
                                                    DateTime.fromISO(row.date),
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {formatTimeNoDateIncludeTZ(
                                                    DateTime.fromISO(
                                                        row.inTime,
                                                    ),
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {formatTimeNoDateIncludeTZ(
                                                    DateTime.fromISO(
                                                        row.outTime,
                                                    ),
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                title={
                                                    'Total: ' +
                                                    convertMinutesToHours(
                                                        regTotal,
                                                    )
                                                }
                                                align="center"
                                            >
                                                {convertMinutesToHours(
                                                    row.regMinutes,
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                title={
                                                    'Total: ' +
                                                    convertMinutesToHours(
                                                        otTotal,
                                                    )
                                                }
                                                align="center"
                                            >
                                                {convertMinutesToHours(
                                                    row.oTMinutes,
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                title={
                                                    'Total: ' +
                                                    convertMinutesToHours(
                                                        dtTotal,
                                                    )
                                                }
                                            >
                                                {convertMinutesToHours(
                                                    row.dTMinutes,
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ),
                                )}
                                <TableRow>
                                    <StyledTableCell align="center">
                                        {}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {}
                                    </StyledTableCell>
                                    <StyledTableCell2 align="center">
                                        Total
                                    </StyledTableCell2>
                                    <StyledTableCell2 align="center">
                                        {convertMinutesToHours(regTotal)}
                                    </StyledTableCell2>
                                    <StyledTableCell2 align="center">
                                        {convertMinutesToHours(otTotal)}
                                    </StyledTableCell2>
                                    <StyledTableCell2 align="center">
                                        {convertMinutesToHours(dtTotal)}
                                    </StyledTableCell2>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            ) : (
                <></>
            )}
        </>
    );
};
