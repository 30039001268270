import * as React from 'react';
import {
    Button,
    Card,
    ClickAwayListener,
    Collapse,
    Grid,
    MenuItem,
    Paper,
    TextField,
    ThemeProvider,
    Typography,
    styled,
} from '@mui/material';
import { defaultTheme, stopJobTime } from '../utilities/Utilites';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useGlobalStore } from '../utilities/GlobalState';
import { StopJobTime } from '../interfaces/Interfaces';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

export const ActiveJobs = (cb: () => void) => {
    const initialStopJobData = {
        Scrap_Code: '',
        Rework_Code: '',
        Notes: '',
        Indirect_Notes: '',
        Transaction_Start: '',
        Transaction_End: DateTime.now().setZone('America/New_York').toString(),
        Employee: '',
        Job: '',
        Operation: 0,
        Ghosted: false,
        Is_Run: 0,
        Completed_Quantity: 0,
        Scrapped_Quantity: 0,
        Percent_Complete: 0,
        Operation_Complete: false,
        Is_Rework: false,
        Transaction_Data: '',
        Job_Operation_Time: '',
    };

    const [drawerOpen, setDrawerOpen] = React.useState<number>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [stopJobData, setStopJobData] =
        React.useState<StopJobTime>(initialStopJobData);
    const globalState = useGlobalStore((state) => state);

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const stopJob = (indexOfItem: number) => {
        setDrawerOpen(indexOfItem);
    };

    const transactionCodes: Array<string> = [
        'Inspect-Error',
        'Inspect-Fail',
        'Machine-Fail',
        'Machine-Wear',
        'Machinist Error',
        'Material-Bad',
        'Material-Damage',
        'Material-Wrong',
        'Misload',
        'Outside Process',
        'Print-Error',
        'Print-Rev',
        'Program-Error',
        'Setup-Error',
        'Setup-Pieces',
        'Tight Spec',
        'Tooling-Break',
        'Tooling-Wear',
        'Tooling-Wrong',
        'Training',
    ];

    const changeNumber = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
    ) => {
        setStopJobData((prev: StopJobTime) => {
            const newStopJobData = {
                ...prev,
                [key]: event.target.value,
            };
            return newStopJobData;
        });
    };

    const changeText = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
    ) => {
        const value: string | number | boolean = e.target.value;

        setStopJobData((prev: StopJobTime) => {
            return {
                ...prev,
                [key]: value,
            };
        });
    };

    const options = transactionCodes.map((code: string) => (
        <MenuItem key={code} value={code}>
            {code}
        </MenuItem>
    ));

    const bodyCells = () =>
        globalState?.activeJobsTableData?.map((row, i) => (
            <React.Fragment key={'StyledTableRoot' + i}>
                <StyledTableRow
                    key={'StyledTableRow' + i}
                    id={'StyledTableRow' + i}
                >
                    <StyledTableCell align="center">
                        {row?.transaction_Type === 10 ? (
                            <Button
                                variant="contained"
                                color="error"
                                startIcon={<DangerousIcon />}
                                sx={{
                                    borderRadius: 10,
                                    '& .MuiButton-startIcon': {
                                        marginRight: '0px',
                                        marginLeft: '0px',
                                    },
                                }}
                                onClick={() => {
                                    stopJob(i);
                                    setStopJobData((prev) => {
                                        return {
                                            ...prev,
                                            Scrap_Code: row?.scrap_Rsn || '',
                                            Rework_Code: '',
                                            Notes: row?.notes || '',
                                            Indirect_Notes: '',
                                            Transaction_Start:
                                                row.transaction_Start,
                                            Transaction_End: DateTime.now()
                                                .setZone('America/New_York')
                                                .toString(),
                                            Employee: row.employee,
                                            Job: row.tD_Job,
                                            Operation: row.target_Integer,
                                            Ghosted: row.ghosted,
                                            Is_Run: row.entry_Type,
                                            Completed_Quantity:
                                                row?.quantity || 0,
                                            Scrapped_Quantity:
                                                row?.scrap_Qty || 0,
                                            Percent_Complete:
                                                row?.percent_Complete || 0,
                                            Operation_Complete:
                                                row?.complete_Operation ||
                                                false,
                                            Is_Rework: row.rework,
                                            Transaction_Data:
                                                row.transaction_Data,
                                            Job_Operation_Time:
                                                row?.tD_Linked_Tran_String ||
                                                '',
                                        };
                                    });
                                }}
                            />
                        ) : (
                            <CheckCircleOutlineIcon color="success" />
                        )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row?.job}</StyledTableCell>
                    <StyledTableCell align="center">
                        {row?.work_Center}
                    </StyledTableCell>
                    <StyledTableCell component="th" align="center" scope="row">
                        {row?.target_Integer}
                    </StyledTableCell>
                </StyledTableRow>
                {drawerOpen !== i ||
                !globalState?.activeJobsTableData?.length ? (
                    <></>
                ) : (
                    <Card
                        sx={{
                            p: '24px',
                            width: (window.innerWidth || 48) - 87 || '0',
                            position: 'absolute',
                            zIndex: '1',
                        }}
                    >
                        <ClickAwayListener
                            onClickAway={() => {
                                setStopJobData(initialStopJobData);
                                setDrawerOpen(undefined);
                            }}
                        >
                            <Collapse in={drawerOpen === i}>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            Stop Job
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="Quantity"
                                            label="Quantity Completed"
                                            id="Quantity"
                                            type="number"
                                            variant="outlined"
                                            onChange={(
                                                e: React.ChangeEvent<
                                                    | HTMLInputElement
                                                    | HTMLTextAreaElement
                                                >,
                                            ) =>
                                                changeNumber(
                                                    e,
                                                    'Completed_Quantity',
                                                )
                                            }
                                            value={
                                                stopJobData?.Completed_Quantity
                                            }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="ScrapQuantity"
                                            label="Scrap Quantity"
                                            id="ScrapQuantity"
                                            type="number"
                                            variant="outlined"
                                            onChange={(
                                                e: React.ChangeEvent<
                                                    | HTMLInputElement
                                                    | HTMLTextAreaElement
                                                >,
                                            ) =>
                                                changeNumber(
                                                    e,
                                                    'Scrapped_Quantity',
                                                )
                                            }
                                            value={
                                                stopJobData?.Scrapped_Quantity
                                            }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField
                                            margin="normal"
                                            required={
                                                stopJobData &&
                                                stopJobData?.Scrapped_Quantity >
                                                    0
                                            }
                                            fullWidth
                                            name="Scrap_Code"
                                            label="Scrap Code"
                                            id="Scrap_Code"
                                            select
                                            SelectProps={{
                                                MenuProps: {
                                                    disablePortal: true,
                                                },
                                            }}
                                            variant="outlined"
                                            onChange={(e) =>
                                                changeText(e, 'Scrap_Code')
                                            }
                                            value={stopJobData?.Scrap_Code}
                                        >
                                            {options}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            name="CompletionPercentage"
                                            label="Completion Percentage"
                                            id="CompletionPercentage"
                                            type="number"
                                            variant="outlined"
                                            onChange={(e) =>
                                                changeText(
                                                    e,
                                                    'Percent_Complete',
                                                )
                                            }
                                            value={
                                                stopJobData?.Percent_Complete
                                            }
                                        />
                                    </Grid>
                                    <Grid item sm={10} xs={12}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            name="Notes"
                                            label="Work Center Notes"
                                            type="Notes"
                                            id="Notes"
                                            autoComplete="Notes"
                                            onChange={(e) =>
                                                changeText(e, 'Notes')
                                            }
                                            value={stopJobData?.Notes}
                                        />
                                    </Grid>
                                    <Grid item sm={2} xs={12}>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            color="error"
                                            startIcon={<DangerousIcon />}
                                            sx={{
                                                borderRadius: 10,
                                                '& .MuiButton-startIcon': {
                                                    marginRight: '0px',
                                                    marginLeft: '0px',
                                                },
                                                marginTop: '20px',
                                                padding: '12px 22px',
                                            }}
                                            onClick={() => {
                                                setLoading(true);
                                                if (
                                                    !stopJobData.Scrap_Code
                                                        ?.length &&
                                                    stopJobData?.Scrapped_Quantity
                                                ) {
                                                    toast.error(
                                                        'Scrap Code must be selected if Scrap Quantity is greater than 0',
                                                    );
                                                    return;
                                                }
                                                return stopJobTime({
                                                    ...stopJobData,
                                                    Operation:
                                                        stopJobData.Operation.toString(),
                                                    Ghosted: stopJobData.Ghosted
                                                        ? 1
                                                        : 0,
                                                    Operation_Complete:
                                                        stopJobData.Operation_Complete
                                                            ? 1
                                                            : 0,
                                                    Is_Rework:
                                                        stopJobData.Is_Rework
                                                            ? 1
                                                            : 0,
                                                    Job_Operation_Time:
                                                        stopJobData.Job_Operation_Time,
                                                }).then(() => {
                                                    setStopJobData(
                                                        initialStopJobData,
                                                    );
                                                    if (cb) {
                                                        setTimeout(() => {
                                                            cb();
                                                        }, 1500);
                                                    }
                                                    setDrawerOpen(undefined);
                                                    setLoading(false);
                                                });
                                            }}
                                        >
                                            Stop
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </ClickAwayListener>
                    </Card>
                )}
            </React.Fragment>
        ));

    return globalState?.activeJobsTableData?.length && !loading ? (
        <ThemeProvider theme={defaultTheme}>
            <Card sx={{ padding: '10px', margin: '10px' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 762 }} aria-label="customized table">
                        <TableHead sx={{ backgroundColor: 'lightgrey' }}>
                            <TableRow>
                                <StyledTableCell align="center">
                                    Action
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Job
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Work Center
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Operation
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{bodyCells()}</TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </ThemeProvider>
    ) : (
        <></>
    );
};
