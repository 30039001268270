import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
    Copyright,
    defaultTheme,
    fetchAttendances,
    fetchEmployee,
} from '../utilities/Utilites';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../utilities/GlobalState';
import { FormHelperText } from '@mui/material';
import { toast } from 'react-toastify';

export const Login = () => {
    const globalState = useGlobalStore((state) => state);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [nameError, setNameError] = React.useState(false);
    const [passError, setPassError] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (open) {
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }, [open]);

    const fetchAllData = (username: string) => {
        return fetchEmployee(username).then((res: any) => {
            globalState.setEmployee(res.data);
            return fetchAttendances(username).then((res1: any) => {
                globalState.setAttendances(res1.data);
            });
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const username: string = event.currentTarget.username.value;
        const password: string = event.currentTarget.password.value;
        const body = JSON.stringify({
            Username: username,
            Password: password,
        });

        if (!username) {
            setNameError(true);
        } else {
            setNameError(false);
        }
        if (!password) {
            setPassError(true);
        } else {
            setPassError(false);
        }

        if (username && password) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    APIKey: 'd2a27b020b264952b043a1ea385c2130',
                },
            };
            axios
                .post(
                    'https://synthesis.aptmfg.com:7114/Authenticate',
                    body,
                    config,
                )
                .then((res: AxiosResponse) => {
                    if (res.status === 200) {
                        globalState.clearState();
                        useGlobalStore.persist.clearStorage();
                        globalState.setUsername(username);
                        globalState.setAuthenticated(true);
                        fetchAllData(username).then(() => navigate('/'));
                    } else {
                        globalState.setUsername('');
                        globalState.setAuthenticated(false);
                        setMessage(res.status + res.statusText);
                        setOpen(true);
                    }
                })
                .catch((error: AxiosError) => {
                    globalState.setUsername('');
                    globalState.setAuthenticated(false);
                    if (error.message == 'Request failed with status code 401')
                        setMessage('Credentials are invalid!');
                    else setMessage(error.message);
                    setOpen(true);
                });
        } else {
            setMessage('Credentials are invalid!');
            globalState.setUsername('');
            globalState.setAuthenticated(false);
            setOpen(true);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            {open ? toast.error(message, { toastId: 'error' }) : <></>}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        APT - Work Management
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            helperText={
                                nameError ? (
                                    <FormHelperText
                                        id="component-helper-text"
                                        error
                                    >
                                        {'Username is required!'}
                                    </FormHelperText>
                                ) : (
                                    <></>
                                )
                            }
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={
                                passError ? (
                                    <FormHelperText
                                        id="component-helper-text"
                                        error
                                    >
                                        {'Password is required!'}
                                    </FormHelperText>
                                ) : (
                                    <></>
                                )
                            }
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            {/* <Grid item xs>
                            <Link href="#" variant="body2">
                            Forgot password?
                            </Link>
                        </Grid> */}
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
};
