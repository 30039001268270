import React from 'react';
import './styles/App.css';
import { Login } from './login/Login';
import { Route, Routes } from 'react-router-dom';
import { HeaderNavBar } from './navBar/HeaderNavBar';
import { JobEntries } from './jobEntries/JobEntries';
import { TimeManagement } from './TimeManagement/TimeManagement';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalStore } from './utilities/GlobalState';

export const App: React.FC = () => {
    const globalState = useGlobalStore((state) => state);
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <HeaderNavBar />
            <Routes>
                <Route
                    path="/"
                    element={
                        globalState.authenticated ? (
                            <TimeManagement />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/myjobs"
                    element={
                        globalState.authenticated ? <JobEntries /> : <Login />
                    }
                />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Login />} />
            </Routes>
        </>
    );
};
