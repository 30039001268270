import {
    Button,
    Card,
    Collapse,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListSubheader,
    MenuItem,
    Paper,
    Stack,
    Switch,
    TextField,
    ThemeProvider,
    Typography,
    alpha,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import * as React from 'react';
import { SearchableDropdown } from '../utilities/SearchableDropdown';
import {
    defaultTheme,
    fetchJobs,
    fetchUserTransactions,
    fetchWorkCenter,
    formatDateNoTimeMonth,
    formatDateWithTimeAndT,
    startJobTime,
} from '../utilities/Utilites';
import {
    Checks,
    Job,
    PartialJob,
    StartStopJob,
    // Transaction,
    WorkCenter,
} from '../interfaces/Interfaces';
import styled from '@emotion/styled';
import { red } from '@mui/material/colors';
import { useGlobalStore } from '../utilities/GlobalState';
import { ActiveJobs } from './ActiveJobs';
import { ChecklistModal } from './ChecklistModal';
import { Get } from '../utilities/Requests';
import { DateTime } from 'luxon';

interface WorkCenterMapped {
    operationStr: string;
    workCenterStr: string;
    statuStr: string;
    job_Operation: number;
}

export const JobEntries = () => {
    const globalState = useGlobalStore((state) => state);
    const [jobSearchPredictorOpen, setJobSearchPredictorOpen] =
        React.useState(false);
    const [jobSearchText, setJobSearchText] = React.useState('');
    const [jobsDrawerOpen, setJobsDrawerOpen] = React.useState(false);
    const [workCenterDrawerOpen, setWorkCenterDrawerOpen] =
        React.useState(false);
    const [jobData, setJobData] = React.useState<Array<Job>>([]);
    const [selectedJob, setSelectedJob] = React.useState({
        job: '',
        description: '',
        part_Number: '',
    });
    const [jobWorkCenters, setJobWorkCenters] = React.useState([]);
    const [selectedWorkCenter, setSelectedWorkCenter] = React.useState({
        operationStr: '',
        workCenterStr: '',
        statuStr: '',
        job_Operation: 0,
    });
    const [isRun, setIsRun] = React.useState(true);
    const [ghosted, setGhosted] = React.useState(false);
    const [countActiveJobs, setCountActiveJobs] = React.useState(0);
    const [checklistedMachinesToday, setChecklistedMachinesToday] =
        React.useState([]);
    const [checkListOpen, setCheckListOpen] = React.useState(false);

    const getTransactionsToday = (employee: string) => {
        return fetchUserTransactions(employee, {
            workDateStart: formatDateWithTimeAndT(
                DateTime.now().setZone('America/New_York').set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                }),
            ),
        }).then((res: any) => {
            return res.data;
        });
    };

    React.useEffect(() => {
        fetchJobs({
            orderDateStart: null,
            orderDateEnd: null,
            job: null,
            quote: null,
            customer: null,
            topLevelJob: null,
            description: null,
            salesCode: null,
            statusCode: null,
            partNumber: null,
        }).then((res: any) => {
            const jobsArray = res.data.map((job: Job) => {
                return {
                    job: job.job,
                    description: job.description,
                    part_Number: job.part_Number,
                };
            });
            setJobData(jobsArray);
        });
        getTransactionsToday(globalState?.employee?.employee || '').then(
            (transRes) => {
                const active = transRes.filter((trans: any) => {
                    return trans.transaction_Type === 10;
                });
                const stoppedToday = transRes.filter((trans: any) => {
                    return trans.transaction_Type === 11;
                });
                globalState.setActiveJobsTableData([
                    ...active,
                    ...stoppedToday,
                ]);
            },
        );
    }, []);
    React.useEffect(() => {
        if (selectedJob?.job?.length) {
            fetchWorkCenter(selectedJob?.job).then((res: any) => {
                const wcArray = res.data.map((wc: WorkCenter) => {
                    return {
                        operationStr: wc.operation_Service,
                        workCenterStr: wc.work_Center,
                        statuStr: wc.status,
                        job_Operation: wc.job_Operation,
                    };
                });
                setJobWorkCenters(wcArray);
            });
        }
        getTransactionsToday(globalState?.employee?.employee || '').then(
            (transRes) => {
                const active = transRes.filter((trans: any) => {
                    return trans.transaction_Type === 10;
                });
                const stoppedToday = transRes.filter((trans: any) => {
                    return trans.transaction_Type === 11;
                });
                globalState.setActiveJobsTableData([
                    ...active,
                    ...stoppedToday,
                ]);
            },
        );
    }, [selectedJob?.job]);

    const getMachineChecklist = (workCenterStr?: string) => {
        Get('/GetMachineChecklistResults')
            .then((res: any) => {
                const workCenterString = workCenterStr?.length
                    ? workCenterStr
                    : selectedWorkCenter.workCenterStr;
                const checkedToday = res.data.filter((check: Checks) => {
                    return (
                        formatDateNoTimeMonth(
                            DateTime.fromJSDate(new Date(check.checked_date)),
                        ) ===
                            formatDateNoTimeMonth(
                                DateTime.now().setZone('America/New_York'),
                            ) && check.machine === workCenterString
                    );
                });
                setChecklistedMachinesToday(checkedToday);
            })
            .catch((e: any) => console.error(e));
    };

    const clickOutsideHandler = (event: MouseEvent) => {
        const target = event.target as HTMLTextAreaElement;

        const jobSearchTextBox = document.getElementById('jobSearchTextBox');

        if (!jobSearchTextBox || !jobSearchText.includes(target?.value)) {
            setJobSearchPredictorOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', clickOutsideHandler);
        return () => {
            document.removeEventListener('click', clickOutsideHandler);
        };
    });

    const RedSwitch = styled(Switch)(() => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: red[600],
            '&:hover': {
                backgroundColor: alpha(red[600], 0.3),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: red[600],
        },
    }));

    const startJob = () => {
        if (globalState?.employee?.employee) {
            const requestBody: Partial<StartStopJob> = {
                Employee: globalState.employee.employee,
                Job: selectedJob.job,
                Operation: selectedWorkCenter.job_Operation,
                Ghosted: ghosted ? 1 : 0,
                Is_Run: isRun,
            };
            if (
                selectedWorkCenter?.workCenterStr
                    ?.substring(0, 3)
                    ?.toUpperCase() === 'CNC' &&
                !checkListOpen &&
                !checklistedMachinesToday?.length
            ) {
                setCheckListOpen(true);
            } else {
                return startJobTime(requestBody).then(() => {
                    globalState.setActiveJobsTableData(undefined);
                    setSelectedWorkCenter({
                        operationStr: '',
                        workCenterStr: '',
                        statuStr: '',
                        job_Operation: 0,
                    });
                    setSelectedJob({
                        job: '',
                        description: '',
                        part_Number: '',
                    });
                    setJobSearchPredictorOpen(false);
                    setJobSearchText('');
                });
            }
        }
    };

    const finalizeStopJob = () => {
        const getJobs = fetchJobs({
            orderDateStart: null,
            orderDateEnd: null,
            job: null,
            quote: null,
            customer: null,
            topLevelJob: null,
            description: null,
            salesCode: null,
            statusCode: null,
            partNumber: null,
        }).then((res: any) => {
            const jobsArray = res.data.map((job: Job) => {
                return {
                    job: job.job,
                    description: job.description,
                    part_Number: job.part_Number,
                };
            });
            setJobData(jobsArray);
        });
        const getTrans = getTransactionsToday(
            globalState?.employee?.employee || '',
        ).then((transRes) => {
            const active = transRes.filter((trans: any) => {
                return (
                    trans.transaction_Type === 10 ||
                    trans.transaction_Type === 11
                );
            });
            globalState.setActiveJobsTableData(active);
        });
        Promise.all([getJobs, getTrans]);
    };

    const canStartJob = () => {
        console.log('curr atttts', globalState?.currentAttendance);
        const att = globalState?.currentAttendance;
        if (
            att?.adjusted_Login?.length &&
            att?.adjusted_Login?.length > 10 &&
            att?.adjusted_Logout === null
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <ChecklistModal
                open={checkListOpen}
                handleClose={() => setCheckListOpen(false)}
                workcenter={selectedWorkCenter?.workCenterStr}
                startJob={startJob}
            />
            <Card
                sx={{
                    padding: '10px 10px 10px 10px',
                    margin: '30px 10px 0 10px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            marginTop: '0',
                            marginLeft: '0',
                        }}
                    >
                        <Grid item xs={12}>
                            <Paper
                                component="form"
                                sx={{
                                    p: '2px 4px',
                                    boxShadow:
                                        !selectedJob?.job?.length &&
                                        !jobsDrawerOpen
                                            ? '1px 3px 3px 3px rgba(0,0,0,0.5), 1px 1px 1px 1px rgba(0,0,0,0.4), 1px 1px 3px 1px rgba(0,0,0,0.8)'
                                            : '1px 1px 1px 1px rgba(0,0,0,0.1), 1px 1px 1px 1px rgba(0,0,0,0.1), 1px 1px 1px 1px rgba(0,0,0,0.1)',
                                }}
                            >
                                <List>
                                    <ListSubheader
                                        sx={{
                                            width: '100%',
                                            paddingLeft: '0px',
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: '100%',
                                                paddingRight: '8px',
                                                paddingLeft: '8px',
                                                left: 'unset',
                                            }}
                                            className="textfield-override"
                                            id="jobSearchTextBox"
                                            label="Job"
                                            autoFocus
                                            variant="outlined"
                                            placeholder="Type HERE to search for a JOB or use the filter button on the right..."
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Divider
                                                            sx={{
                                                                height: 28,
                                                                m: 0.5,
                                                            }}
                                                            orientation="vertical"
                                                        />
                                                        <IconButton
                                                            type="button"
                                                            sx={{ p: '10px' }}
                                                            onClick={() =>
                                                                setJobsDrawerOpen(
                                                                    !jobsDrawerOpen,
                                                                )
                                                            }
                                                        >
                                                            <FilterListIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setJobSearchText(
                                                    e.target.value,
                                                );
                                                if (
                                                    !jobSearchPredictorOpen &&
                                                    !jobsDrawerOpen
                                                ) {
                                                    setJobSearchPredictorOpen(
                                                        true,
                                                    );
                                                }
                                            }}
                                            value={jobSearchText}
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Escape') {
                                                    // Prevents autoselecting item while typing (default Select behaviour)
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                </List>
                            </Paper>
                            <Collapse in={jobSearchPredictorOpen}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        boxShadow:
                                            !selectedJob?.job?.length &&
                                            jobsDrawerOpen
                                                ? '1px 3px 3px 3px rgba(0,0,0,0.5), 1px 1px 1px 1px rgba(0,0,0,0.4), 1px 1px 3px 1px rgba(0,0,0,0.8)'
                                                : '1px 1px 1px 1px rgba(0,0,0,0.1), 1px 1px 1px 1px rgba(0,0,0,0.1), 1px 1px 1px 1px rgba(0,0,0,0.1)',
                                    }}
                                >
                                    <List>
                                        {jobData.map((option, i) => {
                                            if (
                                                option.job
                                                    .toLowerCase()
                                                    .includes(
                                                        jobSearchText.toLowerCase(),
                                                    )
                                            ) {
                                                return (
                                                    <MenuItem
                                                        key={i}
                                                        onClick={() => {
                                                            setSelectedWorkCenter(
                                                                {
                                                                    operationStr:
                                                                        '',
                                                                    workCenterStr:
                                                                        '',
                                                                    statuStr:
                                                                        '',
                                                                    job_Operation: 0,
                                                                },
                                                            );
                                                            setSelectedJob(
                                                                option,
                                                            );
                                                            setJobSearchPredictorOpen(
                                                                false,
                                                            );
                                                            setJobSearchText(
                                                                option.job,
                                                            );
                                                        }}
                                                    >
                                                        {option?.job}
                                                    </MenuItem>
                                                );
                                            } else {
                                                return;
                                            }
                                        })}
                                    </List>
                                </Card>
                            </Collapse>
                            <Collapse in={jobsDrawerOpen}>
                                <Card
                                    sx={{
                                        p: '24px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        boxShadow:
                                            !selectedJob?.job?.length &&
                                            jobsDrawerOpen
                                                ? '1px 3px 3px 3px rgba(0,0,0,0.5), 1px 1px 1px 1px rgba(0,0,0,0.4), 1px 1px 3px 1px rgba(0,0,0,0.8)'
                                                : '1px 1px 1px 1px rgba(0,0,0,0.1), 1px 1px 1px 1px rgba(0,0,0,0.1), 1px 1px 1px 1px rgba(0,0,0,0.1)',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Filters
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SearchableDropdown<PartialJob>
                                                label="Jobs"
                                                selected={selectedJob}
                                                allOptions={jobData}
                                                searchKey="job"
                                                callBackOnSelect={(
                                                    selectedOption,
                                                ) => {
                                                    setJobsDrawerOpen(
                                                        !jobsDrawerOpen,
                                                    );
                                                    setJobSearchText(
                                                        selectedOption.job,
                                                    );
                                                    setSelectedJob(
                                                        selectedOption,
                                                    );
                                                    setSelectedWorkCenter({
                                                        operationStr: '',
                                                        workCenterStr: '',
                                                        statuStr: '',
                                                        job_Operation: 0,
                                                    });
                                                    setChecklistedMachinesToday(
                                                        [],
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SearchableDropdown
                                                label="Part Number"
                                                selected={selectedJob}
                                                allOptions={jobData}
                                                searchKey="part_Number"
                                                callBackOnSelect={(
                                                    selectedOption,
                                                ) => {
                                                    setJobsDrawerOpen(
                                                        !jobsDrawerOpen,
                                                    );
                                                    setJobSearchText(
                                                        selectedOption.job,
                                                    );
                                                    setSelectedJob(
                                                        selectedOption,
                                                    );
                                                    setSelectedWorkCenter({
                                                        operationStr: '',
                                                        workCenterStr: '',
                                                        statuStr: '',
                                                        job_Operation: 0,
                                                    });
                                                    setChecklistedMachinesToday(
                                                        [],
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SearchableDropdown
                                                label="Description"
                                                selected={selectedJob}
                                                allOptions={jobData}
                                                searchKey="description"
                                                callBackOnSelect={(
                                                    selectedOption,
                                                ) => {
                                                    setJobsDrawerOpen(
                                                        !jobsDrawerOpen,
                                                    );
                                                    setJobSearchText(
                                                        selectedOption.job,
                                                    );
                                                    setSelectedJob(
                                                        selectedOption,
                                                    );
                                                    setSelectedWorkCenter({
                                                        operationStr: '',
                                                        workCenterStr: '',
                                                        statuStr: '',
                                                        job_Operation: 0,
                                                    });
                                                    setChecklistedMachinesToday(
                                                        [],
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Collapse>
                            <Grid item xs={12}>
                                {selectedJob?.job?.length && !jobsDrawerOpen ? (
                                    <>
                                        <Paper
                                            component="form"
                                            sx={{
                                                p: '2px 4px',
                                                marginTop: '24px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                boxShadow:
                                                    !selectedWorkCenter
                                                        ?.workCenterStr
                                                        ?.length &&
                                                    !workCenterDrawerOpen
                                                        ? '1px 3px 3px 3px rgba(0,0,0,0.5), 1px 1px 1px 1px rgba(0,0,0,0.4), 1px 1px 3px 1px rgba(0,0,0,0.8)'
                                                        : '1px 1px 1px 1px rgba(0,0,0,0.1), 1px 1px 1px 1px rgba(0,0,0,0.1), 1px 1px 1px 1px rgba(0,0,0,0.1)',
                                            }}
                                        >
                                            <SearchableDropdown<WorkCenterMapped>
                                                sx={{
                                                    width: '100%',
                                                    padding: '10px',
                                                }}
                                                label="Work Center"
                                                selected={selectedWorkCenter}
                                                allOptions={jobWorkCenters}
                                                searchKey="workCenterStr"
                                                callBackOnSelect={(
                                                    selectedOption,
                                                ) => {
                                                    globalState?.activeJobsTableData?.forEach(
                                                        (transaction) => {
                                                            if (
                                                                transaction.transaction_Type ===
                                                                10
                                                            ) {
                                                                setCountActiveJobs(
                                                                    countActiveJobs +
                                                                        1,
                                                                );

                                                                setGhosted(
                                                                    true,
                                                                );
                                                            }
                                                        },
                                                    );
                                                    setWorkCenterDrawerOpen(
                                                        !workCenterDrawerOpen,
                                                    );
                                                    setSelectedWorkCenter({
                                                        operationStr:
                                                            selectedOption.operationStr,
                                                        workCenterStr:
                                                            selectedOption.workCenterStr,
                                                        statuStr:
                                                            selectedOption.statuStr,
                                                        job_Operation:
                                                            selectedOption.job_Operation,
                                                    });
                                                    getMachineChecklist(
                                                        selectedOption.workCenterStr,
                                                    );
                                                }}
                                            />
                                        </Paper>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {selectedWorkCenter?.workCenterStr?.length ? (
                        <>
                            <Grid item xs={6}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography>Setup</Typography>
                                    <RedSwitch
                                        defaultChecked={isRun}
                                        onChange={() => setIsRun(!isRun)}
                                    />
                                    <Typography>Run</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography>Primary</Typography>
                                    <RedSwitch
                                        disabled={countActiveJobs > 0}
                                        defaultChecked={ghosted}
                                        onChange={() => setGhosted(!ghosted)}
                                    />
                                    <Typography>Ghosted</Typography>
                                </Stack>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                sx={{ marginTop: '24px', marginLeft: '16px' }}
                            >
                                <Button
                                    variant="contained"
                                    disabled={canStartJob()}
                                    style={{ width: '100%', height: '50px' }}
                                    onClick={startJob}
                                >
                                    Start
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Card>
            {ActiveJobs(finalizeStopJob)}
        </ThemeProvider>
    );
};
